import type { ReactNode, ButtonHTMLAttributes } from "react";

import { cn } from "@/helpers";

interface AssessmentButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

export default function AssessmentButton({ children }: AssessmentButtonProps) {
  return (
    <div
      className={cn(
        "cursor-pointer select-none rounded border border-slate-700 px-2 py-0.5",
        "font-bold text-slate-300 text-xs",
        "hover:border-slate-300 hover:bg-slate-800 hover:text-slate-200",
      )}
    >
      {children}
    </div>
  );
}
