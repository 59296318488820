import { useRef, useState } from "react";

import ErrorMessage from "@/components/error-message";
import UnknownUserImage from "@/components/common/UnknownUserImage";
import { isImage } from "@/helpers";

export default function AccountImageUpload({ value, onChange }) {
  const [error, setError] = useState(null);
  const [new_upload, setNewUpload] = useState(null);
  const image_input_ref = useRef();

  const _clearImgInput = () => {
    setError(null);
    setNewUpload(null);
    onChange(null);
    if (image_input_ref.current) {
      image_input_ref.current.value = "";
    }
  };

  const _uploadImage = (event) => {
    const file = event.target.files[0];
    if (
      !file.type ||
      file.type.indexOf("image") === -1 ||
      !isImage(file.type)
    ) {
      setError(`File is not an image image format: ${file.type}`);
      setNewUpload(null);
      return;
    }
    if (file.size > 500000) {
      // 5MB
      setError("File size larger than 5MB");
      setNewUpload(null);
      return;
    }
    onChange(file);
    const onLoad = (load_event) => {
      setNewUpload(load_event.target.result);
      setError(null);
    };
    const reader = new FileReader();
    reader.onload = onLoad;
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <div className="flex">
        <input
          type="file"
          className="w-64"
          onChange={_uploadImage}
          accept="image/jpeg, image/png, image/jpg"
          ref={image_input_ref}
        />
        <div
          onClick={_clearImgInput}
          className="material-symbols-rounded notranslate h-8 w-8 cursor-pointer rounded-[50%] p-1 hover:bg-[color:rgba(255,255,255,0.05)]"
          title="Remove image"
        >
          close
        </div>
      </div>
      <ErrorMessage message={error} />
      <div className="my-4">
        {new_upload || value?.url ? (
          <img
            src={new_upload || value?.url}
            className="mx-auto h-[10rem] w-[10rem] rounded-[50%] object-cover"
            alt="Newly uploaded file"
          />
        ) : (
          <UnknownUserImage style="mx-auto h-[10rem] w-[10rem] rounded-[50%] object-cover" />
        )}
      </div>
    </div>
  );
}
