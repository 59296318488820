import { useState } from "react";
import { Link } from "react-router-dom";

import CheckBox from "@/components/common/input/check-box";
import { boxclass, cn, prettyRole } from "@/helpers";
import { useAuth } from "@/hooks/auth";

export default function AssignmentList({ assignments }) {
  const { user } = useAuth();
  const [show_removed, setShowRemoved] = useState(false);
  const [user_assignments, setUserAssignments] = useState([]);

  return (
    <div className="mx-auto max-w-[60rem]">
      {assignments.some((a) => a.removed) && (
        <div className="mb-4 flex items-center justify-end">
          <CheckBox
            label={"Show removed"}
            value={show_removed}
            onChange={(e) => setShowRemoved(e.target.checked)}
          />
        </div>
      )}
      {assignments.length > 0 && (
        <div className="flex flex-col gap-4">
          {assignments
            .sort((a, b) => a.id > b.id)
            .filter((a) => show_removed || !a.removed)
            .map((assignment) => {
              return <Assignment key={assignment.id} assignment={assignment} />;
            })}
        </div>
      )}
      {assignments.filter((a) => !a.removed).length === 0 && (
        <div className="flex justify-center text-slate-400">
          No active assignments
        </div>
      )}
    </div>
  );
}

const Assignment = ({ assignment }) => {
  const { user } = useAuth();

  const base_route = `/assignment/${assignment.id}`;

  return (
    <div className={cn("grid grid-cols-[1fr_16rem_16rem]", boxclass)}>
      <Link
        to={base_route}
        className="row-start-1 block font-medium font-outfit text-2xl"
      >
        {assignment.name}
      </Link>
      <div className="row-start-2 text-slate-400">
        {assignment.organization}
      </div>
      {assignment.user_role && (
        <div className="flex items-center text-slate-400 text-sm">
          <span className="material-symbols-rounded notranslate mr-1">
            account_circle
          </span>
          {prettyRole(assignment.user_role)}
        </div>
      )}
      {assignment.candidate_count > 0 && (
        <div className="row-start-2 flex items-center gap-2">
          <div className="material-symbols-rounded notranslate">
            person_search
          </div>
          {assignment.candidate_count}{" "}
          {assignment.candidate_count === 1 ? "candidate" : "candidates"}
        </div>
      )}
    </div>
  );
};
