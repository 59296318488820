import { useEffect, useRef, useState, cloneElement } from "react";
import { createPortal } from "react-dom";

const Modal = ({ children, onClose, onOpen }) => {
  const [is_active, setActive] = useState(false);

  const modal_element = document.getElementById("modal");

  const _ref = useRef(null);

  const toggleFn = () => {
    if (is_active) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }
    setActive(!is_active);
  };

  const modalHandleClickOutside = (event) => {
    if (_ref.current && !_ref.current.contains(event.target)) {
      setActive(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", modalHandleClickOutside, true);
    if (is_active) {
      document.body.classList.add("overflow-hidden");
      modal_element.classList.add("overflow-y-auto");
      modal_element.classList.add("modal-is-active");
    }
    return () => {
      document.removeEventListener("mousedown", modalHandleClickOutside, true);
      document.body.classList.remove("overflow-hidden");
      modal_element.classList.remove("modal-is-active");
      modal_element.classList.remove("overflow-y-auto");
    };
  }, [is_active]);

  const [first_child, second_child] = children;

  let classNameOverride = {};
  if (first_child.props.className) {
    classNameOverride.className = `${first_child.props.className} cursor-pointer`;
  }

  return (
    <>
      {cloneElement(first_child, {
        onClick: toggleFn,
        ...classNameOverride,
      })}
      {is_active &&
        createPortal(
          <div className="modal-window overscroll-contain" ref={_ref}>
            {cloneElement(second_child, {
              is_active,
              toggleFn,
            })}
          </div>,
          modal_element,
          "modal",
        )}
    </>
  );
};

export default Modal;
