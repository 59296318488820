import clsx from "clsx";

import UnknownUserImage from "./UnknownUserImage";
import { prettyDateString } from "@/helpers";
import { useAuth } from "@/hooks/auth";

export default function Comment({ comment, deleteFn }) {
  const { user } = useAuth();
  const own_comment = user.email === comment.user.email;

  const commentclass = clsx(
    "relative",
    "mb-8",
    "rounded-xl",
    comment.removed_at ? "bg-comment-removed" : "bg-comment",
    "p-4"
  );

  return (
    <div className={commentclass}>
      <div className="mb-2 flex justify-between text-white">
        <div>
          <span>{comment.user.name}</span>{" "}
          {comment.user.title && <span className="opacity-50">({comment.user.title})</span>}
        </div>
        <div className="flex gap-2">
          <div>{prettyDateString(comment.created_at)}</div>
          {own_comment && !comment.removed_at && (
            <div
              onClick={() => deleteFn(comment.id)}
              className="material-symbols-rounded notranslate cursor-pointer"
              title="Remove"
            >
              delete_forever
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {comment.user?.photo_url ? (
          <img className="h-12 w-12 rounded-[50%]" src={comment.user?.photo_url} alt="User" />
        ) : (
          <UnknownUserImage style="h-12 w-12 rounded-[50%]" />
        )}
        {comment.removed_at ? (
          <div className="text-slate-400">{comment.data}</div>
        ) : (
          <div>{comment.data}</div>
        )}
      </div>
      {own_comment ? (
        <SpeechBubble position="right" removed={comment.removed_at} />
      ) : (
        <SpeechBubble position="left" removed={comment.removed_at} />
      )}
    </div>
  );
}

const SpeechBubble = ({ position, removed }) => {
  let left_percentage = 10;
  if (position === "right") {
    left_percentage = 90;
  }
  return (
    <div className={`absolute left-[${left_percentage}%] top-[100%]`}>
      <svg width={20} height={20}>
        <title>SpeechBubble</title>
        <polygon
          points="0,0 20,0 0,20"
          className={removed ? "fill-comment-removed" : "fill-comment"}
        />
      </svg>
    </div>
  );
};
