import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation, Link, NavLink } from "react-router-dom";
import clsx from "clsx";

import { useAuth } from "@/hooks/auth.tsx";
import { hasRole } from "@/helpers.tsx";

const Navigation = () => {
  const { user } = useAuth();

  const linkclass = (isActive) => {
    return clsx(
      "flex items-center px-4 py-2 uppercase tracking-wider font-outfit hover:text-gray-400",
    );
  };

  const links = [
    <li key={"start"}>
      <NavLink to="/" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">home</span>
        Start
      </NavLink>
    </li>,
    hasRole(user, "admin") && (
      <li key="import">
        <NavLink to="import" className={linkclass}>
          <span className="material-symbols-rounded notranslate pr-2">
            cloud_sync
          </span>
          Import
        </NavLink>
      </li>
    ),
    hasRole(user, "admin") && (
      <li key="competencies">
        <NavLink to="competencies" className={linkclass}>
          <span className="material-symbols-rounded notranslate pr-2">
            data_check
          </span>
          Competencies
        </NavLink>
      </li>
    ),
    hasRole(user, "not-in-use") && (
      <li key={"updates"}>
        <NavLink to="updates" className={linkclass}>
          <span className="material-symbols-rounded notranslate pr-2">
            notifications
          </span>
          Updates
        </NavLink>
      </li>
    ),
    <li key={"account-settings"}>
      <NavLink to="account-settings" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          person
        </span>
        Account
      </NavLink>
    </li>,
    <li key={"logout"}>
      <NavLink to="logout" className={linkclass}>
        <span className="material-symbols-rounded notranslate pr-2">
          logout
        </span>
        Sign out
      </NavLink>
    </li>,
  ].filter(Boolean);

  return <BaseNavigation links={links} />;
};

export const BaseNavigation = ({ links }) => {
  const [show_side_nav, setShowSideNav] = useState(false);
  const location = useLocation();
  const side_nav_ref = useRef(null);
  const button_ref = useRef(null);
  const popover_element = document.getElementById("popover");

  useEffect(() => {
    setShowSideNav(false);
  }, [location]);

  useEffect(() => {
    if (show_side_nav) {
      const rect = button_ref.current?.getBoundingClientRect();
      popover_element.style.setProperty("top", `${rect.bottom}px`);
      popover_element.style.setProperty("right", "0px");
      popover_element.classList.add("modal-popover-is-active");
    } else {
      popover_element.classList.remove("modal-popover-is-active");
      popover_element.style.setProperty("top", "unset");
      popover_element.style.setProperty("right", "unset");
    }
    const handleClickOutside = (event) => {
      if (
        side_nav_ref.current &&
        !side_nav_ref.current.contains(event.target)
      ) {
        setShowSideNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      popover_element.classList.remove("modal-popover-is-active");
      popover_element.style.setProperty("top", "unset");
      popover_element.style.setProperty("right", "unset");
    };
  }, [show_side_nav]);

  const side_nav = (
    <ul ref={side_nav_ref} className="rounded bg-midnight-blue p-6 drop-shadow">
      {links}
    </ul>
  );

  return (
    <div className="flex items-center justify-between px-8 py-4">
      <div className="font-medium font-outfit text-3xl">
        <Link to="/">
          <img
            className="max-h-12 py-3"
            src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png"
            width="120"
            alt="Unfold Logotype"
          />
        </Link>
      </div>
      <ul className="flex hidden items-center align-center font-outfit text-base md:flex">
        {links}
      </ul>
      <div className="relative block md:hidden">
        <div
          ref={button_ref}
          className="material-symbols-rounded notranslate cursor-pointer text-3xl"
          onClick={() => setShowSideNav(!show_side_nav)}
        >
          menu
        </div>
        {show_side_nav && createPortal(side_nav, popover_element)}
      </div>
    </div>
  );
};

export default Navigation;
