import CandidatePage, {
  loader as singleCandidateLoader,
} from "@/pages/candidate";
import AssessCandidate, {
  loader as assessCandidateLoader,
  action as assessCandidateAction,
} from "@/pages/assess-candidate.tsx";
import AssessmentAdmin, {
  loader as assessmentAdminLoader,
} from "@/pages/assessment-admin";
import Assignment, {
  loader as singleAssignmentLoader,
} from "@/pages/assignment";
import AssignmentSettings from "@/pages/assignment-settings";
import AssignmentUserAdmin, {
  loader as assignmentUserAdminLoader,
} from "@/pages/assignment-user-admin";
import SingleAssignment, {
  loader as singleAssignmentCandidateLoader,
} from "@/pages/single-assignment";

export const assignment_routes = [
  {
    path: "assignment/:assignment_id",
    element: <Assignment />,
    loader: singleAssignmentLoader,
    id: "single-assignment",
    children: [
      {
        index: true,
        element: <SingleAssignment />,
        loader: singleAssignmentCandidateLoader,
      },
      {
        path: "process-step/:process_step_id",
        element: <SingleAssignment />,
        loader: singleAssignmentCandidateLoader,
      },
      {
        path: "candidate/:candidate_id",
        element: <CandidatePage />,
        loader: singleCandidateLoader,
      },
      {
        path: "grid",
        loader: assessmentAdminLoader,
        element: <AssessmentAdmin />,
      },
      {
        path: "users",
        loader: assignmentUserAdminLoader,
        element: <AssignmentUserAdmin />,
      },
      {
        path: "settings",
        element: <AssignmentSettings />,
      },
      {
        path: "assess/:candidate_id",
        loader: assessCandidateLoader,
        action: assessCandidateAction,
        element: <AssessCandidate />,
      },
    ],
  },
];
