import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import clsx from "clsx";

interface User {
  name?: string;
  email: string;
  photo_url?: string;
}

interface UserAvatarProps {
  size?: string;
  user: User;
}

export default function UserAvatar({ size, user }: UserAvatarProps) {
  const { name, email, photo_url } = user;

  const avatar = useMemo(() => {
    return createAvatar(initials, {
      seed: name || email,
      size: 64,
      randomizeIds: true,
      radius: 50,
      fontWeight: 700,
    }).toDataUriSync();
  }, [name, email]);

  const image_src = photo_url || avatar;

  const imageclass = clsx(
    "rounded-full",
    !size && "h-[28px] w-[28px]",
    size === "medium" && "w-14 h-14",
  );

  return (
    <img
      className={imageclass}
      src={image_src}
      width="28"
      height="28"
      alt="Avatar"
      title={email}
    />
  );
}
