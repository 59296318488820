import { useOutletContext, type LoaderFunctionArgs } from "react-router-dom";

import AssessmentApi from "@/api/assessment-api";
import AssessmentGrid2 from "@/components/assessment-grid-2";

export const loader = async ({
  request,
  params: { assignment_id },
}: LoaderFunctionArgs) => {
  if (!assignment_id) {
    throw new Error("No assignment_id provided");
  }
  const { data: assessment_steps, error: a_error } =
    await AssessmentApi.getAssessmentSteps(assignment_id, request.signal);
  return { assessment_steps, error: a_error };
};

export default function AssessmentAdmin() {
  const [assignment] = useOutletContext();

  return (
    <div className="mb-8">
      <AssessmentGrid2 assignment={assignment} />
    </div>
  );
}
