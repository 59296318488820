import { useRef } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/helpers";

interface StarProps {
  size?: number;
  filled: number;
  value: number;
  mouseEnter: (value: number, ref: React.RefObject<HTMLDivElement>) => void;
  onClick: (value: number, ref: React.RefObject<HTMLDivElement>) => void;
  under_mouse_score: number;
}

export default function Star({
  size = 4,
  filled,
  value,
  mouseEnter,
  onClick,
  under_mouse_score,
}: StarProps) {
  const ref = useRef(null);
  return (
    <div
      ref={ref}
      className={"flex"}
      onMouseMove={() => mouseEnter(value, ref)}
      onClick={() => onClick(value, ref)}
    >
      <div
        style={{
          width: `${size / 8}rem`,
          height: `${size / 4}rem`,
        }}
      >
        {under_mouse_score > 0 ? (
          <StarHalf variant={"blue"} side={"left"} />
        ) : filled > 0 ? (
          <StarHalf variant={"yellow"} side={"left"} />
        ) : (
          <StarHalf variant={"gray"} side={"left"} />
        )}
      </div>
      <div style={{ width: `${size / 8}rem`, height: `${size / 4}rem` }}>
        {under_mouse_score > 0 ? (
          <StarHalf variant={"blue"} side={"right"} />
        ) : filled > 0.5 ? (
          <StarHalf variant={"yellow"} side={"right"} />
        ) : (
          <StarHalf variant={"gray"} side={"right"} />
        )}
      </div>
    </div>
  );
}

const starVariants = cva("", {
  variants: {
    variant: {
      default: "fill-yellow-600",
      yellow: "fill-yellow-600",
      gray: "fill-slate-400",
      blue: "fill-blue-500",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type StarVariantProps = VariantProps<typeof starVariants>;

interface StarHalfProps {
  variant: StarVariantProps["variant"];
  side: "left" | "right";
}

function StarHalf({ variant, side }: StarHalfProps) {
  const points =
    side === "left"
      ? "44,280 62,180 0,106 0,106 92,100 140,0 140,232"
      : "96,275 78,180 140,106 140,106 48,100 0,4 0,232";

  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 140 280"
      preserveAspectRatio="none"
    >
      <g>
        <polygon className={cn(starVariants({ variant }))} points={points} />
      </g>
    </svg>
  );
}
