import { cn } from "@/helpers";

type Counts = {
  all: number;
  done: number;
  user: number;
  user_done: number;
};
type ScoreDisplayProps = {
  score: number | null;
  counts: Counts;
};

export default function ScoreDisplay({ score, counts }: ScoreDisplayProps) {
  const { all, done, user, user_done } = counts;
  const r = 35;
  const circ = 2 * Math.PI * r;
  const all_stroke_pct = ((100 - (done / all) * 100) * circ) / 100;
  const user_stroke_pct = ((100 - (user_done / all) * 100) * circ) / 100;

  if (all === 0) {
    return "-";
  }

  return (
    <div className="rounded-full font-bold tracking-wide">
      <svg width="64" height="64" viewBox="0 0 100 100">
        <title>Average score</title>
        <g transform="rotate(-90 50 50)">
          <circle
            r={r}
            cx="50"
            cy="50"
            fill="transparent"
            className="stroke-slate-800"
            strokeWidth=".75rem"
            strokeDasharray={circ}
            strokeDashoffset="0"
          />
          <circle
            r={r}
            cx="50"
            cy="50"
            fill="transparent"
            className="stroke-blue-800"
            strokeWidth=".75rem"
            strokeDasharray={circ}
            strokeDashoffset={all_stroke_pct}
          />
          <circle
            r={r}
            cx="50"
            cy="50"
            fill="transparent"
            className={cn(
              user_done < user && "stroke-slate-500",
              user_done >= user && "stroke-green-500",
            )}
            strokeWidth=".5rem"
            strokeDasharray={circ}
            strokeDashoffset={user_stroke_pct}
          />
        </g>
        <text
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
          fill="white"
          fontSize={"1.25rem"}
        >
          {Number(score).toFixed(1)}
        </text>
      </svg>
    </div>
  );
}
