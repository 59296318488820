import UnknownUserImage from "@/components/common/UnknownUserImage";
import type { User } from "@/types/user";

type UserBadgeProps = {
  user: User;
  include_email?: boolean;
};

export default function UserBadge({ user, include_email }: UserBadgeProps) {
  return (
    <div className="flex items-center gap-6">
      {user.photo_url ? (
        <img
          src={user.photo_url}
          className="h-[6rem] w-[6rem] rounded-[50%] object-cover"
          alt="User"
        />
      ) : (
        <UnknownUserImage style="h-[6rem] w-[6rem] rounded-[50%] object-cover" />
      )}
      <div>
        <div className="font-bold">{user.name}</div>
        <div className="text-slate-400">{user.title}</div>
        {include_email && <div className="text-slate-300">{user.email}</div>}
      </div>
    </div>
  );
}
