import type React from "react";
import { useState } from "react";

import PaginationTabs from "@/components/common/navigation/pagination-tabs.tsx";
import { renderMarkdown, boxclass, cn } from "@/helpers";

interface Log {
  log_text: string;
  action_date: string;
  created_at: string;
  description: string;
  base_salary: string | number | null;
  variable_salary: string | number | null;
}

interface RenderLogProps {
  title?: string;
  log: Log;
  salary: string | number | null;
}

export default function Logs({ candidate }) {
  const [active_log_idx, setActiveLogIndex] = useState(0);

  const { log: logs, salary }: { logs: Log[]; salary: number | string | null } =
    candidate;

  return (
    <div className="mx-auto mb-8 w-[40rem]">
      <Section>
        {candidate.log.length > 1 && (
          <div>
            <PaginationTabs
              active_option_index={active_log_idx}
              setActiveOptionIndex={setActiveLogIndex}
              options={logs.map((log, idx) => ({
                id: idx,
                text: log.description,
              }))}
            />
            <RenderLog log={logs[active_log_idx]} salary={salary} />
          </div>
        )}
        {candidate.log.length === 1 && (
          <RenderLog
            title={logs[0].description}
            log={logs[0]}
            salary={salary}
          />
        )}
      </Section>
    </div>
  );
}

const Section = ({ children }) => {
  return (
    <div className={cn(boxclass, "p-8")}>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};

const RenderLog = ({ title, log, salary }: RenderLogProps) => {
  let title_element: React.ReactNode | null = null;
  if (title) {
    title_element = (
      <div className="mb-4 flex items-center gap-4 rounded bg-slate-800 px-2 py-1 text-primary">
        {title}
      </div>
    );
  }
  let content = <div className="flex justify-center">Empty</div>;
  try {
    const log_data = JSON.parse(log.log_text.replace("\n", ""));
    content = (
      <div className="content">
        {title_element}
        {Object.values(log_data)
          .filter((log) => log.question)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((qna, i) => {
            return (
              <div key={i}>
                <h2>{qna.question}</h2>
                {qna.answer ? (
                  <div dangerouslySetInnerHTML={renderMarkdown(qna.answer)} />
                ) : (
                  <div className="flex justify-center">Empty</div>
                )}
              </div>
            );
          })}
      </div>
    );
  } catch (e) {
    if (log.log_text) {
      content = (
        <div className="content">
          {title_element}
          <div dangerouslySetInnerHTML={renderMarkdown(log.log_text)} />
        </div>
      );
    }
  }
  return (
    <div className="relative">
      {salary && log.base_salary && (
        <div
          className="absolute top-8 right-8 flex items-center gap-1 opacity-75"
          title="Salary"
        >
          <span className="material-symbols-rounded notranslate">paid</span>{" "}
          {log.base_salary.toLocaleString()}
        </div>
      )}
      {content}
    </div>
  );
};
