const Toggle = ({ checked, onClick, onChange, ...props }) => {
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div onClick={onClick} className="flex">
        <label className="relative inline-flex cursor-pointer items-center">
          <input
            type="checkbox"
            className="peer sr-only"
            checked={checked}
            onChange={onChange ? onChange : null}
            readOnly
            {...props}
          />
          <div className="peer h-6 w-11 rounded-full bg-gray-200  after:absolute  after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
        </label>
      </div>
    </div>
  );
};
export default Toggle;
