import { request } from "@/hooks/request";
import ApiError from "@/models/ApiError";

const UserApi = {
  fetchUser: (
    assignment_id: string,
    email: string,
    abort_signal: AbortSignal,
  ) => {
    return request(`/api/user/assignment/${assignment_id}/${email}`, {
      signal: abort_signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  sendInvite: (
    user_email: string,
    assignment_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/user/invite", {
      method: "post",
      body: JSON.stringify({
        user_email,
        assignment_id,
        origin: window.location.origin,
      }),
      signal: abort_signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default UserApi;
