import {
  useAsyncValue,
  defer,
  type LoaderFunctionArgs,
} from "react-router-dom";

import AssignmentApi from "@/api/assignment-api";
import AssignmentList from "@/components/assignment-list";
import ApiErrorComponent from "@/components/common/api-error";
import DeferredLoading from "@/components/common/deferred-loading";
import { usePageTitle } from "@/hooks/title";

export async function loader({ request }: LoaderFunctionArgs) {
  const { data: assignments, error: assignments_error } =
    await AssignmentApi.fetchAll(request.signal);
  return defer({
    response: {
      data: assignments,
      error: assignments_error,
    },
  });
}

const Start = () => {
  usePageTitle("Start");
  return (
    <DeferredLoading>
      <StartComponent />
    </DeferredLoading>
  );
};

function StartComponent() {
  const { data: assignments, error } = useAsyncValue();

  if (error) {
    return <ApiErrorComponent error={error} />;
  }
  return (
    <div>
      <AssignmentList assignments={assignments} />
    </div>
  );
}

export default Start;
