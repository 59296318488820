import { cn } from "@/helpers";

export default function PaginationTabs({
  options,
  active_option_index,
  setActiveOptionIndex,
}) {
  const optionclass = cn(
    "text-primary",
    "py-1 px-4",
    "cursor-pointer",
    "rounded",
    "flex-1",
    "text-center",
  );
  return (
    <div className="mb-4 flex items-center gap-4 rounded bg-slate-800 p-1">
      {options.map((option, i) => {
        return (
          <div
            key={option.id}
            onClick={() => setActiveOptionIndex(i)}
            className={cn(
              optionclass,
              active_option_index === i && "text-future-purple",
              active_option_index === i && "bg-slate-900",
            )}
          >
            {option.text}
          </div>
        );
      })}
    </div>
  );
}
