import { useEffect } from "react";
import { useForm } from "react-hook-form";

import AssignmentApi from "@/api/assignment-api";
import Button from "@/components/common/input/button";
import Input from "@/components/common/input/input";
import { partialObjectUpdater } from "@/helpers";

export default function AssignmentSettingsForm({
  assignment,
  callbackFn,
  toggleFn,
}) {
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    reset({
      name: assignment?.tcl?.name ? assignment.tcl.name : assignment.name,
    });
  }, []);

  const _handleSubmit = (formdata) => {
    AssignmentApi.update(
      partialObjectUpdater(assignment, {
        ...formdata,
      }),
    ).then(() => {
      if (callbackFn) callbackFn();
      toggleFn();
    });
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      className="mt-4 w-[32rem] rounded-lg bg-slate-800 p-8"
    >
      <Input
        label="Assignment name"
        type="text"
        id="name"
        register={register}
      />
      <div className="flex items-center gap-4">
        <Button type="submit">Submit</Button>
        <Button onClick={_handleCancel}>Cancel</Button>
      </div>
    </form>
  );
}
