import { useState } from "react";
import {
  useOutletContext,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";
import { useForm } from "react-hook-form";

import ApiErrorComponent from "@/components/common/api-error";
import AssignmentApi from "@/api/assignment-api";
import AssignmentUsers from "@/components/assignment-users";
import Button from "@/components/common/input/button";
import Input from "@/components/common/input/input";
import Modal from "@/components/common/modal";
import Select from "@/components/common/input/select";
import { usePageTitle } from "@/hooks/title";

export async function loader({ params }) {
  const { assignment_id } = params;
  const { error, data } = await AssignmentApi.getAssignmentUsers(assignment_id);
  return { error, assignment_users: data };
}

export default function AssignmentUserAdmin() {
  const [assignment] = useOutletContext();
  const { assignment_users, error } = useLoaderData();
  const revalidator = useRevalidator();
  usePageTitle("Users");

  if (error) {
    return <ApiErrorComponent error={error} />;
  }

  function _callbackFn() {
    revalidator.revalidate();
  }

  return (
    <div className="mx-auto max-w-[60rem]">
      <Modal>
        <Button>Add user</Button>
        <AddUserModal assignment_id={assignment.id} callbackFn={_callbackFn} />
      </Modal>
      <AssignmentUsers
        assignment={assignment}
        assignment_users={assignment_users}
      />
    </div>
  );
}

const AddUserModal = ({ assignment_id, callbackFn, toggleFn }) => {
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  async function _handleSubmit(form_data) {
    clearErrors();
    setError(null);
    const { error } = await AssignmentApi.addAssignmentUser(
      form_data.email,
      assignment_id,
      form_data.role,
    );
    if (error) {
      console.error(error);
      setError(error);
    } else {
      toggleFn();
      callbackFn();
    }
  }

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <div className="mx-auto mt-4 w-[32rem] rounded-md bg-slate-800 p-8">
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <Input
          label="Email"
          id="email"
          type="email"
          error={errors.email?.message}
          register={register}
          required
        />
        <Select
          error={errors.role?.message}
          label={"Role"}
          id="role"
          register={register}
          required
        >
          <option value={"assessor"}>Assessor</option>
          <option value={"full_access"}>Full access</option>
        </Select>
        <div className="flex justify-end gap-4">
          <Button onClick={_handleCancel}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
      {error && <ApiErrorComponent error={error} />}
    </div>
  );
};
