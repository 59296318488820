import {
  useRef,
  Children,
  cloneElement,
  Fragment,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";

import Input from "./input/input";
import Button from "./input/button";

const Confirm = ({
  question = "Are you sure?",
  body,
  confirmFn,
  fn_args,
  children,
  in_context_menu,
  required_phrase,
  contextFn,
  callbackFn,
  prevent_default,
  onOpen,
}) => {
  const [active, setActive] = useState(false);
  const is_mounted = useRef(false);
  const [phrase, setPhrase] = useState(null);

  const _handleKeypress = (e) => {
    if (e.keyCode === 13) {
      _handleConfirm();
    }
  };

  useEffect(() => {
    is_mounted.current = true;
    if (active && is_mounted.current) {
      document.getElementById("modal").classList.add("modal-is-active");
      window.addEventListener("keypress", _handleKeypress);
    }
    return () => {
      document.getElementById("modal").classList.remove("modal-is-active");
      window.removeEventListener("keypress", _handleKeypress);
      is_mounted.current = false;
    };
  }, [active]);

  const _toggle = (e) => {
    if (e && prevent_default) {
      e.preventDefault();
    }
    if (is_mounted.current) {
      if (!active) {
        onOpen && onOpen();
      }
      setActive(!active);
    }
  };

  const _handleConfirm = async () => {
    const res = await confirmFn(fn_args);
    callbackFn && (await callbackFn());
    if (res?.toggle !== false) {
      _toggle();
    }
  };

  const child = Children.only(children);
  let first_element = null;

  if (in_context_menu) {
    const specialToggle = () => {
      _toggle();
      contextFn();
    };
    first_element = cloneElement(child, {
      className: "context-menu__item",
      onClick: specialToggle,
    });
  } else {
    first_element = cloneElement(child, {
      onClick: _toggle,
      key: "c1",
    });
  }

  return (
    <Fragment>
      {first_element}
      {active &&
        createPortal(
          <div className="modal-window overscroll-contain rounded-xl bg-midnight-blue p-4 drop-shadow">
            <div className="mb-4">
              <p className="font-outfit text-xl">{question}</p>
              {body && <div>{body}</div>}
            </div>
            {required_phrase && (
              <div className="mb-4">
                <p>To confirm, enter {`"${required_phrase}"`} below.</p>
                <Input
                  value={phrase}
                  placeholder={required_phrase}
                  onChange={(event) => setPhrase(event.target.value)}
                />
              </div>
            )}
            <div className="flex justify-end gap-4">
              <Button
                type="submit"
                onClick={_handleConfirm}
                disabled={required_phrase && phrase != required_phrase}
              >
                Ok
              </Button>
              <Button onClick={_toggle}>Cancel</Button>
            </div>
          </div>,
          document.getElementById("modal"),
        )}
    </Fragment>
  );
};

export default Confirm;
