import { useParams, useLoaderData } from "react-router-dom";

import CandidateInList from "@/components/common/candidate-in-list";
import ProcessSteps from "@/components/process-steps";
import type { LoaderData } from "@/pages/single-assignment";
import { hasRole, cn } from "@/helpers";
import { useAuth } from "@/hooks/auth";

export default function CandidateList({ candidates, assignment }) {
  const { user } = useAuth();
  const { process_step_id } = useParams();
  const { scorecards, assessments } = useLoaderData() as LoaderData;

  if (!candidates || !candidates.length) {
    return <div>This assignment has no candidates</div>;
  }

  const process_step = assignment.process_steps?.find(
    (p) => p.id === Number(process_step_id),
  );

  const candidaterowclass = cn(
    "grid grid-cols-11 items-center gap-2",
    "rounded-md",
    "bg-slate-900",
    "border",
    "border-slate-800",
    "hover:bg-slate-800/70",
    "shadow-md",
  );

  return (
    <>
      <ProcessSteps
        assignment_id={assignment.id}
        steps={assignment.process_steps}
      />
      <div className="flex flex-col gap-2">
        {candidates
          .filter((c) =>
            process_step
              ? process_step.candidate_ids.includes(Number(c.external_id))
              : true,
          )
          .map((candidate) => {
            return (
              <div key={candidate.id} className={candidaterowclass}>
                <CandidateInList
                  candidate={candidate}
                  admin={hasRole(user, "admin")}
                  scorecards={scorecards[candidate.id]}
                  all_competencies_count={assessments.all_competencies_count}
                  user_competencies_count={assessments.user_competencies_count}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}
