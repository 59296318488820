import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import AccountImageUpload from "@/components/account-image-upload";
import Button from "@/components/common/input/button";
import Input from "@/components/common/input/input";
import Spinner from "@/components/common/spinner";
import useRecaptcha from "@/hooks/recaptcha";
import { useAuth } from "@/hooks/auth";

import google_logo from "@/img/google-square.svg";
import msft_logo from "@/img/microsoft-square.svg";

export default function CreateUser() {
  const { user, loading, error, createUserTokenValidation } = useAuth();
  const { token } = useParams();
  const { hide: reHide, verify: reVerify, loading: reLoading } = useRecaptcha();
  const [validation_done, setValidationDone] = useState(false);
  const [manual_creation, setManualCreation] = useState(false);
  const navigate = useNavigate();

  const doneCB = () => {
    const url = new URL(window.location.href);
    // do we have a to param?
    const to_param = url.searchParams.get("to");
    reHide();
    navigate(to_param ? to_param : "/", { replace: true });
  };

  useEffect(() => {
    if (user && user.roles.includes("creating-user")) {
      setValidationDone(true);
    }
  }, [user]);

  const extauthclass = clsx(
    "flex w-72 cursor-pointer justify-center items-center gap-4 rounded-md border-2 px-4 py-2",
    "transition-btn",
    "duration-150",
    "ease-out",
    "hover:ring-4",
    "hover:ring-offset-0",
    "hover:ring-[hsl(210deg 40% 10% / 0.25)]",
  );

  return (
    <div>
      {(reLoading || loading) && (
        <div className="relative flex h-screen w-screen">
          <div className="absolute bottom-0 translate-y-[-50%] md:left-[50%]">
            <Spinner large />
          </div>
        </div>
      )}
      {!validation_done && (
        <div className="mx-auto flex min-h-screen max-w-[36rem] flex-col items-center justify-center">
          <img
            src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png"
            alt="Unfold logotype"
            className="mb-24 w-80"
          />
          <div className="flex justify-center">
            <Button
              onClick={async () =>
                reVerify((result) => createUserTokenValidation(token, result))
              }
            >
              Enter
            </Button>
          </div>
          {error && (
            <div>Something went wrong. Check the link and try again.</div>
          )}
        </div>
      )}
      {validation_done && (
        <>
          {!manual_creation && (
            <div className="mx-auto flex min-h-screen w-[32rem] flex-col flex-col items-center justify-center">
              <div className="my-2">
                <a className={extauthclass} href="/auth/google/create">
                  <img
                    className="w-6"
                    src={google_logo}
                    alt="Google logotype"
                  />{" "}
                  Continue with Google
                </a>
              </div>
              <div className="my-2">
                <a className={extauthclass} href="/auth/microsoft/create">
                  <img
                    className="w-6"
                    src={msft_logo}
                    alt="Microsoft logotype"
                  />{" "}
                  Continue with Microsoft
                </a>
              </div>
              <p>
                Or you can{" "}
                <span
                  onClick={() => setManualCreation(true)}
                  className="cursor-pointer font-medium text-future-purple"
                >
                  create an account manually
                </span>
                .
              </p>
            </div>
          )}
          {manual_creation && (
            <div className="mx-auto my-12 flex max-w-[36rem] flex-col items-center">
              <CreateUserForm onSuccessfullSubmit={doneCB} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const validate_passwords = (first, second) => {
  if (first !== second) {
    return "The passwords do not match";
  }
};

const CreateUserForm = ({ onSuccessfullSubmit }) => {
  const { user, updateUser } = useAuth();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const [photo, setPhoto] = useState(user?.photo);
  if (!user) return null;

  const _handleSubmit = (form_data) => {
    clearErrors();
    updateUser(form_data.name, "", photo, false, form_data.password).then(
      (success) => {
        if (success) {
          onSuccessfullSubmit();
        } else {
          console.error("Error updating user");
        }
      },
    );
  };

  return (
    <div>
      <h1 className="mb-1 text-primary text-xl md:text-2xl">Create Account</h1>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <div className="md:mb-12">
          <Input
            label="Email"
            id="email"
            type="email"
            disabled
            value={user.email}
            error={errors.email?.message}
            required
          />
          <Input label="Name" id="name" register={register} required />
        </div>
        <div className="md:mb-12">
          <Input
            label="Password"
            id="password"
            type="password"
            register={register}
            register_options={{ required: true }}
            error={errors.password?.message}
            required
          />
          <Input
            label="Repeat password"
            id="password_again"
            type="password"
            register={register}
            register_options={{
              required: true,
              validate: (value) => validate_passwords(watch("password"), value),
            }}
            error={errors.password_again?.message}
            required
          />
        </div>
        <div className="md:mb-12">
          <div className="mb-2 inline-block leading-relaxed">Photo</div>
          <AccountImageUpload value={photo} onChange={setPhoto} />
        </div>
        <div className="flex justify-end">
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
};
