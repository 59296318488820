import { useState } from "react";
import { useRevalidator } from "react-router-dom";

import AssignmentApi from "@/api/assignment-api";
import ContextMenu from "@/components/context-menu";
import UserApi from "@/api/user-api";
import UserAvatar from "@/components/user-avatar";
import useSnackbar from "@/hooks/snackbar";
import { copyTextToClipboard, boxclass, cn } from "@/helpers";
import { useAuth } from "@/hooks/auth";

export default function AssignmentUsers({ assignment, assignment_users }) {
  return (
    <div className="mx-auto w-96 space-y-4">
      {assignment_users.map((au) => {
        return <AssignmentUser key={au.email} user={au} />;
      })}
    </div>
  );
}

function AssignmentUser({ user }) {
  const { getCreateUserLink } = useAuth();
  const [invite_is_loading, setInviteIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showSnackbar } = useSnackbar();
  const revalidator = useRevalidator();

  const role_title =
    user.role === "assessor"
      ? "Assessor"
      : user.role === "full_access"
      ? "Full access"
      : user.role === "owner"
      ? "Owner"
      : "Admin";

  function callbackFn() {
    revalidator.revalidate();
  }

  async function _getCreateuserLink() {
    const { error, data } = await getCreateUserLink(user.email);
    if (error) {
      console.error(error);
    } else {
      copyTextToClipboard(
        `${window.location.origin}/create-user/${data.token}`,
      ).then((result) => {
        if (result) {
          showSnackbar("Invitation link copied!");
        } else {
          showSnackbar("Failed to copy link.");
        }
      });
    }
  }

  async function _removeCompletely() {
    // TODO warn on existing data
    await AssignmentApi.removeAssignmentUser(user.email, user.assignment_id);
    callbackFn();
  }

  async function _changeRole(new_role) {
    await AssignmentApi.changeAssignmentUser(
      user.email,
      user.assignment_id,
      new_role,
    );
    callbackFn();
  }

  async function _sendInvite() {
    setInviteIsLoading(true);
    const { error } = await UserApi.sendInvite(user.email, user.assignment_id);
    if (error) {
      console.error(error);
      setError(error);
    }
    setInviteIsLoading(false);
  }

  const context_options = [];
  if (!user.account_created) {
    context_options.push({
      icon: "content_copy",
      label: "Copy invite URL",
      onClick: _getCreateuserLink,
    });
    context_options.push({
      icon: "send",
      label: "Send invite",
      onClick: _sendInvite,
    });
  }
  if (user.role === "assessor") {
    context_options.push({
      icon: "arrow_upward",
      label: "Give full access",
      onClick: () => _changeRole("full_access"),
    });
  }
  if (user.role === "full_access") {
    context_options.push({
      icon: "arrow_downward",
      label: "Make assessor",
      onClick: () => _changeRole("assessor"),
    });
  }
  if (user.role !== "owner") {
    context_options.push({
      icon: "delete",
      label: "Remove",
      onClick: _removeCompletely,
    });
  }

  return (
    <div className={cn("grid grid-cols-[3.5rem_1fr_1rem] gap-4", boxclass)}>
      <UserAvatar size="medium" user={user} />
      <div className="flex flex-col">
        <div>
          {user.account_created ? (
            <span className="font-medium">{user.name}</span>
          ) : (
            <>
              <span className="text-slate-400">* Pending user creation</span>
            </>
          )}
        </div>
        <div className="text-slate-400 text-sm">{user.email}</div>
        <div className="text-future-purple text-sm">{role_title}</div>
      </div>
      <ContextMenu options={context_options} left />
    </div>
  );
}
