import { useReducer, useEffect } from "react";
import { useOutletContext, useRevalidator } from "react-router-dom";

import AssignmentSettingsForm from "@/components/forms/assignment-settings";
import AssignmentApi from "@/api/assignment-api";
import Button from "@/components/common/input/button";
import Toggle from "@/components/common/input/toggle";
import Modal from "@/components/common/modal";
import Spinner from "@/components/common/spinner";
import { usePageTitle } from "@/hooks/title";

const AssignmentSettings = () => {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const [assignment] = useOutletContext();
  const revalidator = useRevalidator();
  usePageTitle("Settings");

  useEffect(() => {
    // sync server state with UI
    dispatch({
      type: "update-checked-state",
      payload: assignment?.settings?.sharing_settings,
    });
  }, [assignment]);

  const _update = () => {
    revalidator.revalidate();
  };

  const _handleSettingChange = ({ id, checked }) => {
    const updated_settings_items = markChecked(
      state.settings_items,
      id,
      checked,
    );
    AssignmentApi.updateSharingSettings(
      assignment.id,
      updated_settings_items,
    ).then(() => {
      revalidator.revalidate();
    });
  };

  async function _manualSync() {
    dispatch({ type: "set-syncing-status", payload: true });
    await AssignmentApi.syncCandidates({
      assignment_id: assignment.id,
    });
    dispatch({ type: "set-syncing-status", payload: false });
    revalidator.revalidate();
  }

  return (
    <div className="mx-auto max-w-[60rem]">
      <div className="mb-8 flex gap-4">
        <Modal>
          <Button>Change name</Button>
          <AssignmentSettingsForm
            assignment={assignment}
            callbackFn={_update}
          />
        </Modal>
        <Button onClick={_manualSync} disabled={state.is_syncing}>
          {state.is_syncing ? <Spinner /> : "Manual sync"}
        </Button>
      </div>
      <div className="mb-4">
        <h2 className="font-bold text-gray-500 text-lg">Sharing settings</h2>
        <p>Controls what&apos;s visible for assessors</p>
      </div>
      <div className="grid grid-cols-2">
        {state.settings_items.map((dp) => {
          return (
            <div key={dp.id} className="mb-2 flex items-center gap-4">
              <Toggle
                onChange={() =>
                  _handleSettingChange({ id: dp.id, checked: !dp.checked })
                }
                checked={dp.checked}
              />
              <p>
                {dp.label}
                <br />
                <span className="text-gray-400 text-sm">{dp.description}</span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set-syncing-status": {
      return { ...state, is_syncing: action.payload };
    }
    case "update-checked-state": {
      const updated_settings_items = state.settings_items.map((setting) => {
        if (action.payload && action.payload[setting.id]) {
          return { ...setting, checked: true };
        }
        return setting;
      });
      return { ...state, settings_items: updated_settings_items };
    }
  }
};

const markChecked = (settings, id, checked) => {
  const new_settings = [...settings];
  const setting_idx = new_settings.findIndex((setting) => setting.id === id);
  new_settings[setting_idx].checked = checked;
  return transformToSettingsItemObject(new_settings);
};

const transformToSettingsItemObject = (settings) => {
  // transforms into shape for backend
  const settings_items = {};
  settings.forEach((setting) => {
    settings_items[setting.id] = setting.checked;
  });
  return settings_items;
};

const initial_state = {
  is_syncing: false,
  settings_items: [
    {
      id: "contact_details",
      label: "Contact details",
      description: "Email address and phone number",
      checked: false,
    },
    {
      id: "url",
      label: "URL",
      description: "In most cases this is a LinkedIn URL",
      checked: false,
    },
    {
      id: "role",
      label: "Role",
      description: "The candidate's role",
      checked: false,
    },
    {
      id: "organization",
      label: "Organization",
      description: "Where the candidate works",
      checked: false,
    },
    {
      id: "comments",
      label: "Comments",
      description: "Comments made on the candidate",
      checked: false,
    },
    {
      id: "action_logs",
      label: "Action logs",
      description: "Actions logs from Ponty",
      checked: false,
    },
    {
      id: "process_step",
      label: "Process step",
      description: "Where in the process the candidate is",
      checked: false,
    },
    {
      id: "salary",
      label: "Salary",
      description: "Salary claim",
      checked: false,
    },
    // {
    //   id: "evali",
    //   label: "evali Results",
    //   description: "Test results from evali",
    //   checked: false,
    // },
  ],
};

export default AssignmentSettings;
