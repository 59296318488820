import { Outlet, Link, useLocation } from "react-router-dom";
import clsx from "clsx";

export default function AssignmentNavigationMenu({
  assignment,
  route_data = [],
}) {
  if (route_data.length < 2) {
    return <Outlet context={[assignment]} />;
  }
  return (
    <>
      <div className="mx-auto w-full md:max-w-[80rem] md:px-2">
        <div className="mb-8">
          <TopNavigation
            assignment_name={assignment.name}
            routes={route_data}
          />
        </div>
      </div>
      <Outlet context={[assignment]} />
    </>
  );
}

const TopNavigation = ({ assignment_name, routes = [] }) => {
  return (
    <div className="flex">
      <div className="mr-4 p-2 font-medium font-outfit text-slate-300">
        {assignment_name}
      </div>
      {routes.map((route, i) => {
        return (
          <NavLink
            key={route.url}
            to={route.url}
            index={route.index}
            extra_match={route.extra_match}
          >
            {route.title}
          </NavLink>
        );
      })}
    </div>
  );
};

const NavLink = (props) => {
  const location = useLocation();

  const is_current_route = props.extra_match
    ? location.pathname.includes(props.extra_match) ||
      location.pathname === props.to
    : props.index
    ? location.pathname === props.to
    : location.pathname.startsWith(props.to);

  const classes = clsx(
    "border-b-3",
    "cursor-pointer",
    "ease",
    "flex",
    "hover:text-almost-white",
    "p-2",
    "select-none",
    "transition-all",
    {
      "hover:border-almost-white": !is_current_route,
      "border-transparent": !is_current_route,
      "text-gray-400": !is_current_route,
      "border-future-purple-400": is_current_route,
      "text-almost-white": is_current_route,
    },
  );
  // remove index from props, React does not like non string indices
  const { index, extra_match, ...props_wo_index } = props;
  return (
    <Link {...props_wo_index} className={classes}>
      {props.children}
    </Link>
  );
};
