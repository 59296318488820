import { useEffect, useState } from "react";

import AssignmentApi from "@/api/assignment-api";
import Button from "@/components/common/input/button";
import CompetencyApi from "@/api/competency";

export default function CompetencyOverrideViewer({
  competency_id,
  assignment_id,
  toggleFn,
}) {
  const [state, setState] = useState({});

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  useEffect(() => {
    const abort_controller = new AbortController();

    const fetchData = async () => {
      const base_data = await CompetencyApi.fetchOne(
        competency_id,
        abort_controller.signal,
      );
      let assignment_specific_data = {};
      if (assignment_id) {
        assignment_specific_data = await AssignmentApi.getAssignmentCompetency(
          assignment_id,
          competency_id,
          abort_controller.signal,
        );
      }
      const combined_data = {
        ...base_data.data,
      };
      // check for overrides
      [
        "description",
        "questions_leader",
        "questions_ic",
        "positive_indicators",
        "negative_indicators",
      ].forEach((key) => {
        if (
          Object.keys(assignment_specific_data.data).includes(key) &&
          assignment_specific_data.data[key] !== null
        ) {
          combined_data[key] = assignment_specific_data.data[key];
        }
      });
      setState(combined_data);
    };
    fetchData();
    return () => {
      abort_controller.abort();
    };
  }, []);

  if (!state.description) {
    return null;
  }

  return (
    <div className="mt-4 w-[60rem] rounded-lg bg-slate-800 p-8">
      <div key={state.id}>
        <h2 className="mb-2 inline-block bg-slate-800 px-4 font-bold text-2xl">
          {state.name}
        </h2>
        <div className="flex items-center justify-between rounded-tr-md bg-slate-800 p-4">
          <p className="max-w-[40rem] text-slate-300">{state.description}</p>
        </div>
        <>
          <div className="space-y-8 rounded-t-md bg-slate-900 px-4 py-8">
            <p className="font-bold text-slate-100">Questions</p>
            {state.questions_leader.map((q, idx) => {
              return (
                <p key={idx} className="max-w-[40rem]">
                  {q}
                </p>
              );
            })}
          </div>
          <div className="grid grid-cols-2">
            <div className="rounded-bl-md bg-green-200 px-4 pt-4 pb-2">
              <p className="pb-2 font-bold text-slate-600">
                Positive indicators
              </p>
              <ul className="ml-4 list-disc text-neutral-900">
                {state.positive_indicators.map((pi, idx) => {
                  return (
                    <li key={idx} className="mb-2">
                      {pi}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="rounded-br-md bg-red-200 px-4 pt-4 pb-2">
              <p className="pb-2 font-bold text-slate-600">
                Negative indicators
              </p>
              <ul className="ml-4 list-disc text-neutral-900">
                {state.negative_indicators.map((ni, idx) => {
                  return (
                    <li key={idx} className="mb-2">
                      {ni}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      </div>
      <div className="mt-4">
        <Button onClick={_handleCancel}>Close</Button>
      </div>
    </div>
  );
}
