import { useEffect } from "react";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";

import FullPageSpinner from "@/components/common/full-page-spinner";
import Navigation from "@/components/navigation";
import { useAuth } from "@/hooks/auth";
import { hasRole } from "@/helpers";

interface User {
  roles?: string[];
}

export default function App(): JSX.Element {
  const { pathname } = useLocation();
  const { user, setLoading, is_loading } = useAuth();
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let destination = "";
    if (user === null) {
      destination = "/login";
    } else if (hasRole(user as User, "guest")) {
      destination = "/login";
    }
    if (destination) {
      let search_str = "";
      if (pathname !== "/" && pathname !== "/logout") {
        // the user does not want index path, help them
        const url_params = new URLSearchParams();
        url_params.set("to", pathname);
        search_str = url_params.toString();
      }
      navigate(
        { pathname: destination, search: search_str },
        { replace: true },
      );
    }
    if (user) {
      setLoading(false);
    }
  }, [user]);

  if (!user || is_loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Navigation />
      <ScrollRestoration />
      <Outlet />
    </>
  );
}
