import { useState } from "react";
import clsx from "clsx";

import Star from "@/components/star";

export default function StarRating({
  size = 4,
  score = 0,
  max_score = 3,
  onScoreChanged,
}) {
  const [score_under_mouse, setScoreUnderMouse] = useState(0);

  const _mouseEnter = (star_score, star_ref) => {
    star_ref.current.classList.remove("star-rating-jump");
    setScoreUnderMouse(star_score);
  };

  const _mouseLeave = () => {
    setScoreUnderMouse(0);
  };

  const _mouseDown = (star_score, star_ref) => {
    setScoreUnderMouse(0);
    star_ref.current.classList.add("star-rating-jump");
    onScoreChanged && onScoreChanged(star_score);
  };

  const stars_class = clsx("flex", "cursor-pointer");

  return (
    <div className={stars_class} onMouseLeave={_mouseLeave}>
      {[...Array(max_score)].map((_, i) => {
        return (
          <Star
            key={i}
            size={size}
            filled={score - i}
            under_mouse_score={score_under_mouse - i}
            value={i + 1}
            mouseEnter={_mouseEnter}
            onClick={_mouseDown}
          />
        );
      })}
    </div>
  );
}
