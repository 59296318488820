import { boxclass, cn } from "@/helpers";
import UserAvatar from "@/components/user-avatar";

export default function ScoreCard({ competencies, assessment }) {
  function competencyAvg(competency_id) {
    if (!Object.keys(assessment.scores).includes(competency_id)) return 0;
    return (
      assessment.scores[competency_id].reduce(
        (acc, curr) => acc + curr.score,
        0,
      ) / assessment.scores[competency_id].length
    );
  }
  return (
    <div className={cn(boxclass, "mb-8")}>
      {competencies.map((comp) => {
        const avg = competencyAvg(comp.id);
        const scoreclass = cn(
          "col-start-2 bg-midnight-blue p-2",
          avg !== 0 && "font-bold",
          avg === 0 && "col-span-2",
        );
        return (
          <div
            key={comp.id}
            className="mb-4 grid grid-cols-[20rem_2rem_1fr] gap-y-2"
          >
            <div className="bg-midnight-blue p-2">
              <span className="font-bold">{comp.name}</span>
            </div>
            <div className={scoreclass}>
              {avg === 0 ? "Not assessed yet" : avg}
            </div>
            {avg !== 0 && <div className="bg-midnight-blue p-2" />}
            {Object.keys(assessment.scores).includes(comp.id) &&
              assessment.scores[comp.id].map((user_score) => {
                let note: React.ReactNode | null = null;
                if (Object.keys(assessment.notes).includes(comp.id)) {
                  const user_note = assessment.notes[comp.id].filter(
                    (note) => note.user.id === user_score.user.id,
                  );
                  if (user_note.length) {
                    note = (
                      <div className="col-span-1 col-start-3 p-2 text-sm">
                        {user_note[0].note}
                      </div>
                    );
                  }
                }
                return (
                  <div
                    key={user_score.user.id}
                    className="col-span-3 grid grid-cols-[20rem_2rem_1fr]"
                  >
                    <div className="col-start-1 flex gap-2 p-2 leading-3">
                      <UserAvatar user={user_score.user} />
                      <div>
                        {user_score.user.name}
                        <br />
                        <span className="text-slate-400 text-sm">
                          {user_score.user.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-start-2 p-2">{user_score.score}</div>
                    {note}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
}
