import App from "@/app.tsx";
import Logout from "@/components/logout.tsx";
import AccountSettings from "@/pages/account-settings.tsx";
// import Updates from "@/pages/updates.tsx";

import Start, { loader as startLoader } from "@/pages/start.tsx";
import Competencies, {
  loader as competenciesLoader,
} from "@/pages/competencies";
import AssignmentImport, {
  loader as assignmentImportLoader,
} from "@/pages/assignment-import.tsx";
import { assignment_routes } from "@/routes/assignment.tsx";

export const app_routes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Start />,
        loader: startLoader,
      },
      ...assignment_routes,
      {
        path: "import",
        loader: assignmentImportLoader,
        element: <AssignmentImport />,
      },
      {
        path: "competencies",
        loader: competenciesLoader,
        element: <Competencies />,
      },
      // Updates does not use loader data to avoid double non-idempotent fetch calls on page reload.
      // {
      //   path: "updates",
      //   element: <Updates />,
      // },
      // {
      //   path: "updates/:cursor_id",
      //   element: <Updates />,
      // },
      { path: "account-settings", element: <AccountSettings /> },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <h1>404 Not found</h1>,
      },
    ],
  },
];
