import { useEffect } from "react";

export function usePageTitle(title) {
  useEffect(() => {
    if (title) {
      document.title = `${title} - Unfold Platform`;
    } else {
      document.title = "Unfold Platform";
    }
  }, [title]);
  return;
}
