import { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";

import Button from "@/components/common/input/button";
import Input from "@/components/common/input/input";
import Select from "@/components/common/input/select";
import Textarea from "@/components/common/input/textarea";
import ListInput from "@/components/common/input/list";
import CompetencyApi from "@/api/competency";
import AssignmentApi from "@/api/assignment-api";

const initial_state = {
  positive_indicators: [],
  negative_indicators: [],
  questions_leader: [],
  questions_ic: [],
  is_evali: false,
  area_options: [
    // also change backend when modifying
    { id: "business", name: "Business" },
    { id: "leadership", name: "Leadership" },
    { id: "tech", name: "Tech" },
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "add-positive-indicator":
      return {
        ...state,
        positive_indicators: action.payload,
      };
    case "add-negative-indicator":
      return {
        ...state,
        negative_indicators: action.payload,
      };
    case "add-question-leader":
      return {
        ...state,
        questions_leader: action.payload,
      };
    case "add-question-ic":
      return {
        ...state,
        questions_ic: action.payload,
      };
    case "is-evali":
      return { ...state, is_evali: action.payload };
    case "add-area-option": {
      const new_options = state.area_options;
      new_options.push(action.payload);
      return { ...state, area_options: new_options };
    }
  }
};

export default function CompetencyForm({
  id,
  assignment_id,
  toggleFn,
  callbackFn,
}) {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { register, handleSubmit, reset } = useForm();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const abort_controller = new AbortController();

    const fetchData = async () => {
      const base_data = await CompetencyApi.fetchOne(
        id,
        abort_controller.signal,
      );
      // special case to lock down evali competencies
      if (base_data.data?.is_evali) {
        dispatch({ type: "is-evali", payload: true });
        dispatch({
          type: "add-area-option",
          payload: { id: base_data.data.area_slug, name: base_data.data.area },
        });
      }
      let assignment_specific_data = {};
      if (assignment_id) {
        assignment_specific_data = await AssignmentApi.getAssignmentCompetency(
          assignment_id,
          id,
          abort_controller.signal,
        );
      }
      const combined_data = {
        ...base_data.data,
      };
      // check for overrides
      [
        "name",
        "description",
        "questions_leader",
        "questions_ic",
        "positive_indicators",
        "negative_indicators",
      ].forEach((key) => {
        if (
          "data" in assignment_specific_data &&
          Object.keys(assignment_specific_data.data).includes(key) &&
          assignment_specific_data.data[key] !== null
        ) {
          combined_data[key] = assignment_specific_data.data[key];
        }
      });
      reset(combined_data);
      dispatch({
        type: "add-positive-indicator",
        payload: combined_data.positive_indicators,
      });
      dispatch({
        type: "add-negative-indicator",
        payload: combined_data.negative_indicators,
      });
      dispatch({
        type: "add-question-leader",
        payload: combined_data.questions_leader,
      });
      dispatch({
        type: "add-question-ic",
        payload: combined_data.questions_ic,
      });
    };
    if (id) {
      fetchData();
    }
    return () => {
      abort_controller.abort();
    };
  }, [id]);

  const _handleSubmit = (data) => {
    [
      "positive_indicators",
      "negative_indicators",
      "questions_leader",
      "questions_ic",
    ].forEach((key) => {
      data[key] = state[key];
    });
    if (assignment_id) {
      // these are overrides, save accordingly
      AssignmentApi.addCompetencyToAssignment(
        assignment_id.toString(),
        id,
        data,
      ).then(({ data }) => {
        // to let parent component resume behind modal action
        if (callbackFn) callbackFn(data);
        toggleFn();
      });
    } else {
      // this is a base competency edit
      CompetencyApi.save({ data, competency_id: id ? id : null }).then(
        ({ data }) => {
          // to let parent component resume behind modal action
          if (callbackFn) callbackFn(data);
          toggleFn();
        },
      );
    }
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      className="mt-4 w-[60rem] rounded-lg bg-slate-800 p-8"
    >
      {state.is_evali && (
        <div className="mb-4 rounded-md border-2 border-red-700 bg-red-300 p-4 text-red-700">
          This is an evali competency, modify with care!
        </div>
      )}
      <Input label="Name" id="name" register={register} />
      <Select
        label="Category"
        id="area_slug"
        register={register}
        disabled={assignment_id}
      >
        <option />
        {state.area_options.map((ao) => {
          return (
            <option key={ao.id} value={ao.id}>
              {ao.name}
            </option>
          );
        })}
      </Select>
      <Textarea
        label="Description"
        id="description"
        register={register}
        disabled={state.is_evali}
      />
      <div className="grid grid-cols-2 gap-8">
        <ListInput
          items={state.positive_indicators}
          label="Positive indicators"
          name="positive_indicators"
          callbackFn={(new_values) =>
            dispatch({ type: "add-positive-indicator", payload: new_values })
          }
        />
        <ListInput
          items={state.negative_indicators}
          label="Negative indicators"
          name="negative_indicators"
          callbackFn={(new_values) =>
            dispatch({ type: "add-negative-indicator", payload: new_values })
          }
        />
        <ListInput
          items={state.questions_leader}
          label="Questions leader"
          name="questions_leader"
          callbackFn={(new_values) =>
            dispatch({ type: "add-question-leader", payload: new_values })
          }
        />
        <ListInput
          items={state.questions_ic}
          label="Questions IC"
          name="questions_ic"
          callbackFn={(new_values) =>
            dispatch({ type: "add-question-ic", payload: new_values })
          }
        />
      </div>
      <div className="flex items-center gap-4">
        <Button type="submit">Submit</Button>
        <Button onClick={_handleCancel}>Cancel</Button>
      </div>
    </form>
  );
}
