import { request } from "@/hooks/request";
import ApiError from "@/models/ApiError";

const CompetencyApi = (() => {
  const base_url = "/api/competency";
  return {
    fetchOne: (competency_id: string, abort_signal: AbortSignal) => {
      return request(`/api/competency/${competency_id}`, {
        signal: abort_signal,
      }).then((response) => {
        if (response.is_ok) {
          return { data: response.json, error: null };
        } else {
          return { data: null, error: new ApiError(response) };
        }
      });
    },

    fetchAll: (competency_slugs: string = [], abort_signal: AbortSignal) => {
      const url = new URL(base_url);
      if (competency_slugs.length > 0) {
        url.searchParams.set("slugs", competency_slugs);
      }
      return request(url.toString(), {
        signal: abort_signal,
      }).then((response) => {
        if (response.is_ok) {
          return { data: response.json, error: null };
        } else {
          return { data: null, error: new ApiError(response) };
        }
      });
    },

    save: ({
      competency_id,
      data,
      abort_signal,
    }: {
      competency_id: string;
      data: any;
      abort_signal: AbortSignal;
    }) => {
      let url = base_url;
      if (competency_id) {
        url = `${url}/${competency_id}`;
      }
      return request(url, {
        signal: abort_signal,
        method: "post",
        body: JSON.stringify(data),
      });
    },
  };
})();

export default CompetencyApi;
