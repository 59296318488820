import { useForm } from "react-hook-form";

import AssessmentApi from "@/api/assessment-api";
import Button from "@/components/common/input/button";
import Input from "@/components/common/input/input";
import useSnackbar from "@/hooks/snackbar";

export default function AssessorForm({
  assignment_id,
  assessment_step_id,
  callbackFn,
  toggleFn,
}) {
  const { showSnackbar } = useSnackbar();
  const { register, handleSubmit } = useForm();

  const _handleSubmit = (formdata) => {
    const abort_controller = new AbortController();
    const { email } = formdata;
    AssessmentApi.addAssessmentUser(
      email,
      assignment_id,
      assessment_step_id,
      abort_controller.signal,
    ).then(({ error, data }) => {
      if (error) {
        if (error.status === 409) {
          showSnackbar(`Assessor ${email} already added!`);
        } else {
          console.error(error);
        }
      } else {
        if (callbackFn) callbackFn(data);
        showSnackbar(`Assessor ${email} added!`);
      }
      toggleFn();
    });
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      className="mt-4 w-[40rem] rounded-lg bg-slate-800 p-8"
    >
      <Input label="E-mail address" id="email" register={register} />

      <div className="flex items-center gap-4">
        <Button type="submit">Submit</Button>
        <Button onClick={_handleCancel}>Cancel</Button>
      </div>
    </form>
  );
}
