import { useEffect } from "react";
import { useForm } from "react-hook-form";

import Input from "../common/input/input";
import Button from "../common/input/button";

export default function AssessmentStepForm({ name, callbackFn, toggleFn }) {
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    if (name) {
      reset({ name: name });
    }
  }, [name]);

  const _handleSubmit = (data) => {
    callbackFn(data.name);
    toggleFn();
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      className="mt-4 w-[32rem] rounded-lg bg-slate-800 p-8"
    >
      <Input id="name" name="name" register={register} label="Name" />
      <div className="flex items-center gap-4">
        <Button type="submit">Submit</Button>
        <Button onClick={_handleCancel}>Cancel</Button>
      </div>
    </form>
  );
}
