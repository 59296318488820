import { useState } from "react";

const CheckBox = ({ value, onChange, label, ...props }) => {
  // TODO Fix styling of this component. Style is taken from a Tailwind example but the check mark is not displayed
  const styles = [
    "form-check-input",
    "appearance-none",
    "h-4",
    "w-4",
    "border",
    "border-gray-300",
    "rounded-sm",
    "bg-white",
    "checked:border-2",
    "checked:bg-future-purple-400",
    "checked:border-[color:hsl(257deg 92% 71% / 0.5)]",
    "focus:outline-none",
    "transition",
    "duration-200",
    "mt-1",
    "align-top",
    "bg-no-repeat",
    "bg-center",
    "bg-contain",
    "float-left",
    "mr-2",
    "cursor-pointer",
  ];
  return (
    <div className="form-check">
      <input
        className={styles.join(" ")}
        type="checkbox"
        value={value}
        onChange={onChange}
        {...props}
      />
      {label && (
        <label className="form-check-label inline-block">{label}</label>
      )}
    </div>
  );
};

export default CheckBox;
