import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "@/auth-cookie";
import ApiError from "@/models/ApiError";

const default_headers = {
  "content-type": "application/json",
  accept: "application/json",
};

export const request = async (url, options, include_default_headers = true) => {
  let response_body;
  let final_options = {};
  final_options = {
    ...options,
    headers: {
      ...(include_default_headers ? default_headers : options.headers),
    },
  };

  const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
  if (jwt !== undefined) {
    final_options = {
      ...final_options,
      headers: {
        ...final_options.headers,
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  return fetch(url, final_options).then(async (response) => {
    let response_body;
    try {
      response_body = await response.json();
    } catch (e) {
      response_body = {};
    }

    return {
      json: response_body,
      status: response.status,
      is_ok: response.ok,
    };
  });

  // FIXME refresh dance when we have jwt but get a 401
  // const response = await fetch(url, final_options);
  //
  // try {
  //   response_body = await response.json();
  // } catch (e) {
  //   response_body = {};
  // }
  // return {
  //   json: response_body,
  //   status: response.status,
  //   is_ok: response.ok,
  // };
};

export const useRequest = ({ abort_controller }) => {
  const requestFn = (url, options, include_default_headers = true) => {
    const final_options = { signal: abort_controller.signal, ...options };
    return request(url, final_options, include_default_headers).catch((err) => {
      if (err.name === "AbortError") {
        return { message: "Request aborted" };
      } else {
        throw err;
      }
    });
  };

  return requestFn;
};

export function handleResponse(response: any) {
  if (response.is_ok) {
    return { data: response.json, error: null };
  } else {
    return { data: null, error: new ApiError(response) };
  }
}
