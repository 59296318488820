import { useReducer, useEffect } from "react";
import clsx from "clsx";

import AssessmentApi from "@/api/assessment-api";
import AssessmentStepForm from "@/components/forms/assessment-step";
import AssessorForm from "@/components/forms/assessor";
import AssignmentApi from "@/api/assignment-api";
import Button from "@/components/common/input/button";
import CompetencyBrowser from "@/components/competency-browser";
import CompetencyForm from "@/components/forms/competency";
import CompetencyOverrideViewer from "@/components/competency-override-viewer";
import Confirm from "@/components/common/confirm";
import ContextMenu from "@/components/context-menu";
import Modal from "@/components/common/modal";
import UserAvatar from "@/components/user-avatar";
import { usePageTitle } from "@/hooks/title";
import { hasRole } from "@/helpers";
import { useAuth } from "@/hooks/auth";

export default function AssessmentGrid2({ assignment }) {
  const { user } = useAuth();
  usePageTitle("Assessment Grid");
  const [state, dispatch] = useReducer(reducer, initial_state);

  useEffect(() => {
    const abort_controller = new AbortController();
    AssignmentApi.getAssignmentCompetencies(
      assignment.id,
      abort_controller.signal,
    ).then(({ data }) => {
      dispatch({ type: ACTION.add_assignment_competencies, payload: data });
    });
    AssessmentApi.getAssessmentSteps(
      assignment.id,
      abort_controller.signal,
    ).then(({ data }) => {
      dispatch({ type: ACTION.add_assessment_steps, payload: data });
    });
    AssessmentApi.getAssessmentUsers(
      {
        assignment_id: assignment.id,
      },
      abort_controller.signal,
    ).then(({ data }) => {
      dispatch({ type: ACTION.add_assessors, payload: data });
    });
    return () => {
      abort_controller.abort();
    };
  }, [assignment.id]);

  const _addAssessmentStep = (title) => {
    AssessmentApi.addAssessmentStep(assignment.id, title).then(
      ({ error, data }) => {
        if (error) {
          console.error(error);
        } else {
          dispatch({ type: ACTION.add_assessment_step, payload: data });
        }
      },
    );
  };

  const _addCompetencyToStep = (e) => {
    const competency_id = e.currentTarget.getAttribute("data-competency-id");
    const process_step_id = e.currentTarget.getAttribute(
      "data-process-step-id",
    );
    AssessmentApi.addCompetencyToAssessmentStep(
      process_step_id,
      competency_id,
    ).then(({ data, error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  const _addCompetencyToAssignment = (selected) => {
    AssignmentApi.addCompetenciesToAssignment(assignment.id, selected).then(
      ({ error }) => {
        if (error) {
          console.error(error);
        } else {
          // not very DRY
          AssignmentApi.getAssignmentCompetencies(assignment.id).then(
            ({ data }) => {
              dispatch({
                type: ACTION.add_assignment_competencies,
                payload: data,
              });
            },
          );
        }
      },
    );
  };

  const _removeCompetencyFromStep = (e) => {
    const competency_id = e.currentTarget.getAttribute("data-competency-id");
    const process_step_id = e.currentTarget.getAttribute(
      "data-process-step-id",
    );
    AssessmentApi.removeCompetencyFromAssessmentStep(
      process_step_id,
      competency_id,
    ).then(({ data, error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  const _updateCompetencies = (data) => {
    dispatch({ type: ACTION.update_assignment_competencies, payload: data });
  };

  const _removeCompetency = ({ assignment_id, competency_id }) => {
    AssignmentApi.removeCompetencyFromAssignment(
      assignment_id,
      competency_id,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({
          type: ACTION.remove_assignment_competency,
          payload: competency_id,
        });
      }
    });
  };

  return (
    <>
      <div className="mx-auto max-w-[60rem] pt-4">
        {hasRole(user, "admin") && (
          <div className="flex items-center justify-end gap-4">
            {state.edit_mode && (
              <>
                <Modal>
                  <Button>Add competency</Button>
                  <CompetencyBrowser callbackFn={_addCompetencyToAssignment} />
                </Modal>
                <Modal>
                  <Button>Add assessment step</Button>
                  <AssessmentStepForm callbackFn={_addAssessmentStep} />
                </Modal>
              </>
            )}
            <Button
              primary
              onClick={() => dispatch({ type: ACTION.toggle_edit })}
            >
              {state.edit_mode ? "Stop editing" : "Edit"}
            </Button>
          </div>
        )}
      </div>
      {state.assessment_steps.length === 0 &&
        state.assignment_competencies.length === 0 && (
          <div className="flex justify-center p-4">
            Start by adding a competency and an assessment step.
          </div>
        )}
      <div className="pt-6 pb-12">
        <div className="mx-auto max-w-[80rem]">
          <div className="grid grid-cols-[20rem_1fr] gap-2">
            <div />
            <div className="custom-scrollbar grid grid-cols-5 gap-4">
              {state.assessment_steps
                .sort((a, b) => a.ordering - b.ordering)
                .map((step, idx) => {
                  const is_first = idx === 0;
                  const is_last = idx === state.assessment_steps.length - 1;
                  return (
                    <AssessmentStepUtil
                      key={step.id}
                      parent={{
                        assignment_id: assignment.id,
                        assessors: state.assessors,
                      }}
                      step={step}
                      active={state.edit_mode}
                      is_first={is_first}
                      is_last={is_last}
                      dispatch={dispatch}
                    />
                  );
                })}
            </div>
          </div>
          {state.assignment_competencies
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 0;
              }
              return 0;
            })
            .map((comp) => {
              const context_options = [
                {
                  icon: "settings",
                  label: "Modify",
                  form: (
                    <CompetencyForm
                      id={comp.id}
                      assignment_id={assignment.id}
                      callbackFn={_updateCompetencies}
                    />
                  ),
                },
                {
                  icon: "play_circle",
                  label: "View",
                  form: (
                    <CompetencyOverrideViewer
                      competency_id={comp.id}
                      assignment_id={assignment.id}
                    />
                  ),
                },
                {
                  icon: "delete",
                  label: "Remove",
                  confirm: (
                    <Confirm
                      body={"Remove competency"}
                      fn_args={{
                        assignment_id: assignment.id,
                        competency_id: comp.id,
                      }}
                      confirmFn={_removeCompetency}
                    />
                  ),
                },
              ];

              return (
                <div key={comp.id} className="grid grid-cols-[20rem_1fr] gap-2">
                  <div className="mb-4">
                    <div className="flex items-center font-bold">
                      {state.edit_mode && (
                        <span className="mr-2">
                          <ContextMenu options={context_options} right />
                        </span>
                      )}
                      {comp.is_overridden && (
                        <span
                          className="material-symbols-rounded notranslate pr-1 text-green-400 text-sm"
                          title="This competency is specific for this assignment"
                        >
                          stat_0
                        </span>
                      )}
                      {comp.name}
                    </div>
                    <p className="text-[12px] text-slate-400">
                      {comp.description}
                    </p>
                  </div>
                  <div className="custom-scrollbar grid grid-cols-5 gap-4 overflow-x-auto">
                    {state.assessment_steps
                      .sort((a, b) => a.ordering - b.ordering)
                      .map((step) => {
                        return (
                          <AssessmentStepIcon
                            key={step.id}
                            active={state.edit_mode}
                            step_id={step.id}
                            competency_id={comp.id}
                            checked={
                              step.competency_ids !== null &&
                              step.competency_ids.includes(comp.id)
                            }
                            addFn={_addCompetencyToStep}
                            removeFn={_removeCompetencyFromStep}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

const ACTION = {
  add_assessment_step: "add-assessment-step",
  add_assessment_steps: "add-assessment-steps",
  add_assessors: "add-assessors",
  add_assignment_competencies: "add-assignment-competencies",
  remove_assessment_step: "remove-assessment-step",
  remove_assessor: "remove-assessor",
  remove_assignment_competency: "remove-assignment-competency",
  toggle_edit: "toggle-edit",
  update_assessment_steps: "update-assessment-steps",
  update_assessors: "update-assessors",
  update_assignment_competencies: "update-assignment-competencies",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.add_assessment_step:
      return {
        ...state,
        assessment_steps: [...state.assessment_steps, action.payload],
      };
    case ACTION.add_assessment_steps:
      return { ...state, assessment_steps: action.payload };
    case ACTION.add_assessors: {
      return { ...state, assessors: action.payload };
    }
    case ACTION.update_assessors: {
      return { ...state, assessors: [...state.assessors, action.payload] };
    }
    case ACTION.remove_assessor: {
      const { email, assessment_step_id } = action.payload;
      const new_assessors = state.assessors.filter(
        (assessor) =>
          assessor.email !== email ||
          assessor.assessment_step_id !== assessment_step_id,
      );
      return { ...state, assessors: new_assessors };
    }
    case ACTION.add_assignment_competencies:
      return { ...state, assignment_competencies: action.payload };
    case ACTION.update_assignment_competencies: {
      const new_competencies = state.assignment_competencies.filter(
        (comp) => comp.id !== action.payload.id,
      );
      new_competencies.push(action.payload);
      return { ...state, assignment_competencies: new_competencies };
    }
    case ACTION.remove_assignment_competency: {
      const new_competencies = state.assignment_competencies.filter(
        (comp) => comp.id !== action.payload,
      );
      return { ...state, assignment_competencies: new_competencies };
    }
    case ACTION.update_assessment_steps: {
      // remove the updated step
      const new_steps = state.assessment_steps.filter(
        (step) => step.id !== action.payload.id,
      );
      // add it back with new data
      new_steps.push(action.payload);
      return { ...state, assessment_steps: new_steps };
    }
    case ACTION.remove_assessment_step: {
      const new_steps = state.assessment_steps.filter(
        (as) => as.id !== action.payload,
      );
      return { ...state, assessment_steps: new_steps };
    }
    case ACTION.toggle_edit:
      return { ...state, edit_mode: !state.edit_mode };
  }
};

const initial_state = {
  assessment_steps: [],
  assignment_competencies: [],
  assessors: [],
  edit_mode: false,
};

const AssessmentStepIcon = ({
  step_id,
  active,
  competency_id,
  checked,
  addFn,
  removeFn,
}) => {
  const wrapperclass = clsx(
    "place-self-center",
    "self-center",
    "p-2",
    "text-gray-700",
    active && checked && "cursor-pointer",
  );

  return (
    <div
      className={wrapperclass}
      title={active && checked ? "Click to remove" : undefined}
      data-competency-id={competency_id}
      data-process-step-id={step_id}
      onClick={active && checked ? removeFn : null}
    >
      {checked ? (
        <span className="material-symbols-rounded text-3xl text-green-600">
          check_circle
        </span>
      ) : active ? (
        <span
          className="material-symbols-rounded cursor-pointer cursor-pointer text-3xl hover:text-almost-white"
          title="Add to process step"
          data-competency-id={competency_id}
          data-process-step-id={step_id}
          onClick={addFn}
        >
          add
        </span>
      ) : null}
    </div>
  );
};

const AssessmentStepUtil = ({
  parent,
  step,
  active,
  is_first,
  is_last,
  dispatch,
}) => {
  const _addAssessor = (data) => {
    dispatch({ type: ACTION.update_assessors, payload: data });
  };

  const _removeAssessor = ({ email }) => {
    AssessmentApi.removeAssessmentUser(
      email,
      parent.assignment_id,
      step.id,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({
          type: ACTION.remove_assessor,
          payload: { email, assessment_step_id: step.id },
        });
      }
    });
  };

  const _moveLeft = () => {
    AssessmentApi.changeAssessmentStepOrdering(step.id, false).then(
      ({ data, error }) => _handleMoved({ data, error }),
    );
  };

  const _moveRight = () => {
    AssessmentApi.changeAssessmentStepOrdering(step.id, true).then(
      ({ data, error }) => _handleMoved({ data, error }),
    );
  };

  const _handleMoved = ({ data, error }) => {
    if (error) {
      console.error(error);
    } else {
      dispatch({ type: ACTION.add_assessment_steps, payload: data });
    }
  };

  const _remove = () => {
    AssessmentApi.removeAssessmentStep(step.id).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.remove_assessment_step, payload: step.id });
      }
    });
  };

  const _updateAssessmentStep = (name) => {
    AssessmentApi.updateAssessmentStep({
      step_id: step.id,
      assignment_id: parent.assignment_id,
      title: name,
    }).then(({ error, data }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  const context_options = [
    {
      icon: "person_add",
      label: "Add assessor",
      form: (
        <AssessorForm
          assignment_id={parent.assignment_id}
          assessment_step_id={step.id}
          callbackFn={_addAssessor}
        />
      ),
    },
    {
      icon: "edit",
      label: "Change step name",
      form: (
        <AssessmentStepForm
          name={step.title}
          callbackFn={_updateAssessmentStep}
        />
      ),
    },
  ];
  if (!is_first) {
    context_options.push({
      icon: "arrow_back_ios_new",
      label: "Move left",
      onClick: _moveLeft,
    });
  }
  if (!is_last) {
    context_options.push({
      icon: "arrow_forward_ios",
      label: "Move right",
      onClick: _moveRight,
    });
  }
  context_options.push({
    icon: "delete",
    label: "Delete",
    onClick: _remove,
  });

  return (
    <div key={step.id} className="flex flex-col items-center text-center">
      <div className="flex items-center font-bold">
        {step.title}
        {active && (
          <span className="ml-2">
            <ContextMenu options={context_options} />
          </span>
        )}
      </div>

      <div className="group mt-2 flex flex-row">
        {parent.assessors
          ?.filter((assessor) => step.id === assessor.assessment_step_id)
          .map((assessor) => {
            return (
              <div
                key={assessor.email}
                className="-ml-2 text-[10px] transition-margin first:ml-0 group-hover:ml-1"
              >
                <UserAvatar user={assessor} />
                {active && (
                  <Confirm
                    body={`Removing ${assessor.email}`}
                    fn_args={{
                      email: assessor.email,
                    }}
                    confirmFn={_removeAssessor}
                  >
                    <span
                      className="material-symbols-rounded invisible cursor-pointer text-slate-600 hover:text-almost-white group-hover:visible"
                      data-email={assessor.email}
                    >
                      delete
                    </span>
                  </Confirm>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
