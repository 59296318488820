import { request } from "@/hooks/request";
import ApiError from "@/models/ApiError";

const CommentApi = {
  fetchCandidateComments: (candidate_id: string, abort_signal: AbortSignal) => {
    return request(`/api/comment/candidate/${candidate_id}`, {
      signal: abort_signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  postCandidateComment: (comment: any, abort_signal: AbortSignal) => {
    return request("/api/comment/candidate", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify(comment),
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
  deleteCandidateComment: (comment_id: string, abort_signal: AbortSignal) => {
    return request(`/api/comment/${comment_id}`, {
      method: "delete",
      signal: abort_signal,
    }).then((response) => {
      if (response.is_ok) {
        return {
          data: response.json,
          error: null,
        };
      } else {
        return { data: null, error: new ApiError(response) };
      }
    });
  },
};

export default CommentApi;
