import { useState } from "react";
import { useForm } from "react-hook-form";

import AccountImageUpload from "@/components/account-image-upload";
import Button from "@/components/common/input/button";
import ErrorMessage from "@/components/error-message";
import Input from "@/components/common/input/input";
import Modal from "@/components/common/modal";
import UserBadge from "@/components/user-badge";
import { useAuth } from "@/hooks/auth";
import useSnackbar from "@/hooks/snackbar";
import type { User } from "@/types/user";

const AccountSettings = ({ onSuccessfullSubmit }) => {
  const { user }: { user: User } = useAuth();

  return (
    <div className="mx-auto mt-12 max-w-[40rem]">
      <div className="flex items-center justify-between">
        <UserBadge user={user} include_email />
        <div className="flex flex-col gap-4">
          <Modal>
            <Button>Edit</Button>
            <EditUserForm callbackFn={onSuccessfullSubmit} />
          </Modal>
          {!user.external_auth && (
            <Modal>
              <Button>Change password</Button>
              <UpdatePasswordForm />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

const UpdatePasswordForm = ({ toggleFn }) => {
  const { error, updateUserPassword } = useAuth();
  const [show_password, setShowPassword] = useState(false);
  const { showSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const _handleSubmit = (form_data) => {
    clearErrors();
    if (form_data.new_password !== form_data.new_password_again) {
      setError("new_password_again", { message: "Passwords do not match" });
      return;
    }
    updateUserPassword(form_data.new_password, form_data.current_password).then(
      (response) => {
        if (response.is_ok) {
          reset();
          showSnackbar("Password changed");
          toggleFn();
        } else {
          setError("current_password", {
            message:
              response.json.detail === "error/wrong-password"
                ? "Wrong password"
                : response?.json?.detail || error,
          });
        }
      },
    );
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <div className="mt-4 w-[30rem] rounded-lg bg-slate-800 p-8">
      <div className="mb-2 flex justify-between">
        <div className="text-slate-400 text-xl">Change password</div>
        <div
          onClick={() => setShowPassword(!show_password)}
          title={show_password ? "Hide password" : "Show password"}
          className="material-symbols-rounded notranslate cursor-pointer select-none"
        >
          {show_password ? "visibility" : "visibility_off"}
        </div>
      </div>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <Input
          type={show_password ? "text" : "password"}
          label="New password"
          id="new_password"
          error={errors.new_password?.message}
          register={register}
          required
        />
        <Input
          type={show_password ? "text" : "password"}
          label="New password again"
          id="new_password_again"
          error={errors.new_password_again?.message}
          register={register}
          required
        />
        <Input
          type={show_password ? "text" : "password"}
          label="Current password"
          id="current_password"
          error={errors.current_password?.message}
          register={register}
          required
        />
        <div className="flex items-center gap-4">
          <Button type="submit">Update password</Button>
          <Button onClick={_handleCancel}>Cancel</Button>
        </div>
      </form>
    </div>
  );
};

const EditUserForm = ({ toggleFn, callbackFn }) => {
  const [error, setError] = useState(null);
  const { user, updateUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...user } });
  const [photo, setPhoto] = useState(user.photo);

  const _onSubmit = (form_data) => {
    updateUser(
      form_data.name,
      form_data.title || "",
      photo,
      Boolean(user.photo && !photo),
    )
      .then((success) => {
        if (success) {
          callbackFn();
          toggleFn();
        } else {
          setError("Server error. Please try again.");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const _handleCancel = (ev) => {
    ev.preventDefault();
    toggleFn();
  };

  return (
    <div className="mt-4 w-[30rem] rounded-lg bg-slate-800 p-8">
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Input
          type="text"
          label="Name"
          id="name"
          register={register}
          register_options={{ required: true }}
          error={errors.name}
          required
        />
        <Input type="text" label="Title" register={register} id="title" />
        <AccountImageUpload value={photo} onChange={setPhoto} />
        <div className="flex items-center gap-4">
          <Button type="submit">Submit</Button>
          <Button onClick={_handleCancel}>Cancel</Button>
        </div>
      </form>
      <ErrorMessage message={error} />
    </div>
  );
};

export default AccountSettings;
