import { useState } from "react";

import Input from "./input";
import Button from "./button";

const ListInput = ({ items, label, name, callbackFn }) => {
  const [input_state, setInputState] = useState("");

  const _addItem = (e) => {
    e.preventDefault();
    const value_to_add = input_state;
    // bail if empty
    if (value_to_add === "") return;
    // bail if item already exists
    if (items.indexOf(value_to_add) !== -1) return;
    if (callbackFn) {
      callbackFn([...items, value_to_add]);
    }
    setInputState("");
  };

  const _removeItem = (item) => {
    if (callbackFn) {
      const new_items = items.filter((existing_item) => existing_item !== item);
      callbackFn([...new_items]);
    }
  };

  const _handleInputChange = (ev) => {
    setInputState(ev.currentTarget.value);
  };

  const _handleReturn = (ev) => {
    // making sure return clicks adds to list
    if (ev.keyCode === 13) {
      _addItem(ev);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <Input
          type="text"
          label={label}
          name={name}
          onKeyDown={_handleReturn}
          onChange={_handleInputChange}
          value={input_state}
        />
        <Button aux_classes="grow-0 ml-4 mt-[5px]" onClick={_addItem}>
          <span className="material-symbols-rounded">add</span> Add
        </Button>
      </div>
      {items &&
        items.map((item, idx) => {
          return (
            <div
              key={idx}
              className="flex items-center justify-between gap-2 rounded-sm border-2 border-transparent p-2 hover:border-slate-700"
            >
              {item}{" "}
              <span
                className="material-symbols-rounded cursor-pointer"
                onClick={() => _removeItem(item)}
              >
                delete
              </span>
            </div>
          );
        })}
    </div>
  );
};

ListInput.displayName = "ListInput";

export default ListInput;
