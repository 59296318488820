import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";

import ApiErrorComponent from "@/components/common/api-error";
import FullPageSpinner from "@/components/common/full-page-spinner";

/* Used with defer() loader function */

export default function DeferredLoading({ children }) {
  const loader_data = useLoaderData();
  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Await
        resolve={loader_data?.response}
        errorElement={<ApiErrorComponent />}
      >
        {children}
      </Await>
    </Suspense>
  );
}
